import React from 'react';
import { Accordion, Flex, Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';

import { useTranslations } from '../../../../contexts/LocalizationContext';
import { ReturnCodesHowItWorksAccordionItem } from './ReturnCodesHowItWorksAccordionItem';

interface ReturnCodesHowItWorksModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ReturnCodesHowItWorksModal = ({ isOpen, onClose }: ReturnCodesHowItWorksModalProps) => {
  const translations = useTranslations();
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm">
      <ModalOverlay />
      <ModalContent h="70%" position="fixed" bottom="1%" right="1%" transform="none">
        <ModalHeader>{translations('return_codes_how_it_works_modal_header')}</ModalHeader>
        <ModalCloseButton />
        <Flex h="100%" display="column" p={2}>
          <Accordion allowToggle>
            <ReturnCodesHowItWorksAccordionItem
              header={translations('return_codes_how_it_works_modal_creating_new_code_header')}
              listItems={[
                translations('return_codes_how_it_works_modal_creating_new_code_content_point_1'),
                translations('return_codes_how_it_works_modal_creating_new_code_content_point_2'),
                translations('return_codes_how_it_works_modal_creating_new_code_content_point_3'),
                translations('return_codes_how_it_works_modal_creating_new_code_content_point_4'),
              ]}
            ></ReturnCodesHowItWorksAccordionItem>
            <ReturnCodesHowItWorksAccordionItem
              header={translations('return_codes_how_it_works_modal_deactivating_an_old_code_header')}
              listItems={[
                translations('return_codes_how_it_works_modal_deactivating_an_old_code_content_point_1'),
                translations('return_codes_how_it_works_modal_deactivating_an_old_code_content_point_2'),
                translations('return_codes_how_it_works_modal_deactivating_an_old_code_content_point_3'),
              ]}
            ></ReturnCodesHowItWorksAccordionItem>
            <ReturnCodesHowItWorksAccordionItem
              header={translations('return_codes_how_it_works_modal_managing_multiple_codes_header')}
              listItems={[
                translations('return_codes_how_it_works_modal_managing_multiple_codes_content_point_1'),
                translations('return_codes_how_it_works_modal_managing_multiple_codes_content_point_2'),
                translations('return_codes_how_it_works_modal_managing_multiple_codes_content_point_3'),
              ]}
            ></ReturnCodesHowItWorksAccordionItem>
            <ReturnCodesHowItWorksAccordionItem
              header={translations('return_codes_how_it_works_modal_troubleshooting_scanning_issues_header')}
              listItems={[
                translations('return_codes_how_it_works_modal_troubleshooting_scanning_issues_content_point_1'),
                translations('return_codes_how_it_works_modal_troubleshooting_scanning_issues_content_point_2'),
                translations('return_codes_how_it_works_modal_troubleshooting_scanning_issues_content_point_3'),
              ]}
            ></ReturnCodesHowItWorksAccordionItem>
            <ReturnCodesHowItWorksAccordionItem
              header={translations('return_codes_how_it_works_modal_best_practices_header')}
              listItems={[
                translations('return_codes_how_it_works_modal_best_practices_content_point_1'),
                translations('return_codes_how_it_works_modal_best_practices_content_point_2'),
                translations('return_codes_how_it_works_modal_best_practices_content_point_3'),
              ]}
            ></ReturnCodesHowItWorksAccordionItem>
          </Accordion>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
