import React from 'react';
import { Button, Flex, Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';

import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useReturnCodesContext } from '../../context/hooks/useReturnCodesContext';

export const ReturnCodesCreatedModal = () => {
  const translations = useTranslations();
  const { returnCodesState, handleCloseCreatedModal } = useReturnCodesContext();

  const isCreatedModalOpen = returnCodesState.isCreatedModalOpen;

  return (
    <Modal isOpen={isCreatedModalOpen} onClose={handleCloseCreatedModal} size="md">
      <ModalOverlay />
      <ModalContent transform="none">
        <ModalHeader>{translations('return_codes_created_modal_header')}</ModalHeader>
        <ModalCloseButton />
        <Flex h="100%" display="column" p={2}>
          <Text mb={2} fontSize={'small'}>
            {translations('return_codes_created_modal_content')}
          </Text>
          <Button width={'100%'} mb={2} onClick={handleCloseCreatedModal}>
            {translations('return_codes_created_modal_close_button')}
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
