import React, { JSX } from 'react';
import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  BoxProps,
  Button,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useLocalizationContext } from '../../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { useCitiesForReturnCodesFilters } from '../../../../services/return-codes';
import colors from '../../../../styles/colors';
import { useReturnCodesContext } from '../../context/hooks/useReturnCodesContext';
import { LocationWithIssueType } from '../../context/model/LocationWithIssueType';
import { ReturnCodeAgeType } from '../../context/model/ReturnCodeAgeType';
import { visibleScrollStyle } from '../../utils';

interface ReturnCodesFiltersModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface FiltersCheckboxProps<T extends string> {
  value: T;
  stateToCheck: T | T[] | null;
  handle: (value: T | null) => void;
  valueToShow: string;
  handleSameValueOnChange: boolean;
  bgColor?: BoxProps['bgColor'];
  borderColor?: BoxProps['borderColor'];
}

const FiltersCheckbox = <T extends string>({
  value,
  stateToCheck,
  handle,
  valueToShow,
  handleSameValueOnChange,
  bgColor,
  borderColor,
}: FiltersCheckboxProps<T>) => {
  return (
    <Box
      bgColor={bgColor ?? colors.beige[50]}
      borderRadius={'lg'}
      pb={2}
      pt={2}
      pr={5}
      pl={5}
      mb={2}
      border={borderColor ? `1px solid ${borderColor}` : undefined}
    >
      <Checkbox
        colorScheme="orange"
        size="sm"
        value={value}
        mr={'5'}
        isChecked={Array.isArray(stateToCheck) ? stateToCheck.includes(value) : stateToCheck === value}
        onChange={(event) => {
          if (!handleSameValueOnChange && !event.target.checked) {
            handle(null);
          } else {
            handle(event.target.value as T);
          }
        }}
      >
        {valueToShow}
      </Checkbox>
    </Box>
  );
};

export const ReturnCodesFiltersModal = ({ isOpen, onClose }: ReturnCodesFiltersModalProps): JSX.Element => {
  const translations = useTranslations();
  const {
    returnCodesState,
    handleSetLocationWithIssueType,
    handleSetReturnCodeAgeType,
    handleSetSearchCityText,
    handleSelectCity,
    handleResetFilters,
  } = useReturnCodesContext();
  const { language } = useLocalizationContext();
  const { data } = useCitiesForReturnCodesFilters({
    returnCodeAge: returnCodesState.selectedReturnCodeAgeType,
    searchLocationText: returnCodesState.searchLocation,
    locationWithIssueType: returnCodesState.selectedLocationWithIssueType,
    cityIds: [],
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent h="auto" w="auto" transform="none">
        <ModalHeader>{translations('return_codes_filters_modal_header')}</ModalHeader>
        <ModalCloseButton />

        <Flex h="100%" display="column" p={4} width={'auto'}>
          <Box mb={'5px'} fontSize={'medium'} maxH="450px" overflowY={'scroll'} mr={0} sx={visibleScrollStyle}>
            <Box borderRadius="md" mb={'5px'} fontSize={'medium'}>
              <Text fontWeight={'bold'}>{translations('return_codes_filters_modal_return_codes_issues')}</Text>
              <FiltersCheckbox
                handleSameValueOnChange={false}
                value={LocationWithIssueType.NO_ACTIVE_RETURN_CODE}
                stateToCheck={returnCodesState.selectedLocationWithIssueType}
                handle={handleSetLocationWithIssueType}
                valueToShow={translations('return_codes_filters_modal_return_codes_issues_no_active_return_code')}
              ></FiltersCheckbox>
              <FiltersCheckbox
                handleSameValueOnChange={false}
                value={LocationWithIssueType.NO_RETURNS_IN_PAST_30_DAYS}
                stateToCheck={returnCodesState.selectedLocationWithIssueType}
                handle={handleSetLocationWithIssueType}
                valueToShow={translations('return_codes_filters_modal_return_codes_issues_no_returns_in_past_30_days')}
              ></FiltersCheckbox>
              {/* <FiltersCheckbox */}
              {/*   handleSameValueOnChange={false} */}
              {/*   value={LocationWithIssueType.RETURN_CODE_USAGE_HAS_DECLINED_SHARPLY} */}
              {/*   stateToCheck={returnCodesState.selectedLocationWithIssueType} */}
              {/*   handle={handleSetLocationWithIssueType} */}
              {/*   valueToShow={translations('return_codes_filters_modal_return_codes_issues_return_code_usage_declined')} */}
              {/* ></FiltersCheckbox> */}
            </Box>
            <Box borderRadius="md" mb={'5px'} fontSize={'medium'}>
              <Text fontWeight={'bold'}>{translations('return_codes_filters_modal_return_code_age')}</Text>
              <FiltersCheckbox
                borderColor={'#4D4B4B'}
                handleSameValueOnChange={false}
                value={ReturnCodeAgeType.OLDER_THAN_30_DAYS}
                stateToCheck={returnCodesState.selectedReturnCodeAgeType}
                handle={handleSetReturnCodeAgeType}
                valueToShow={translations('return_codes_filters_modal_return_code_age_older_than', {
                  '{{days}}': '30',
                })}
              ></FiltersCheckbox>
              <FiltersCheckbox
                borderColor={'#F0B719'}
                handleSameValueOnChange={false}
                value={ReturnCodeAgeType.OLDER_THAN_60_DAYS}
                stateToCheck={returnCodesState.selectedReturnCodeAgeType}
                handle={handleSetReturnCodeAgeType}
                valueToShow={translations('return_codes_filters_modal_return_code_age_older_than', {
                  '{{days}}': '60',
                })}
              ></FiltersCheckbox>
              <FiltersCheckbox
                borderColor={'#E82A2A'}
                handleSameValueOnChange={false}
                value={ReturnCodeAgeType.OLDER_THAN_90_DAYS}
                stateToCheck={returnCodesState.selectedReturnCodeAgeType}
                handle={handleSetReturnCodeAgeType}
                valueToShow={translations('return_codes_filters_modal_return_code_age_older_than', {
                  '{{days}}': '90',
                })}
              ></FiltersCheckbox>
            </Box>
            <Box borderRadius="md" mb={'5px'} fontSize={'medium'}>
              <Text fontWeight={'bold'}>{translations('return_codes_filters_modal_city')}</Text>
              <Text fontSize={'small'}>{translations('return_codes_filters_modal_city_description')}</Text>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="grey.200" />
                </InputLeftElement>
                <Input
                  aria-label={translations('return_codes_filters_modal_city_search')}
                  _focus={{ bgColor: colors.beige[50] }}
                  _hover={{ bgColor: colors.beige[50] }}
                  variant={'whiteFilled'}
                  value={returnCodesState.searchCity}
                  placeholder={translations('return_codes_searchbox_placeholder')}
                  onChange={(value) => {
                    handleSetSearchCityText(value.target.value);
                  }}
                  bgColor={colors.beige[50]}
                />
              </InputGroup>
              {data
                ?.filter((c) =>
                  (c.cityNameTranslations[language] || '')
                    .toLowerCase()
                    .includes((returnCodesState.searchCity || '').toLowerCase()),
                )
                .map((c) => (
                  <FiltersCheckbox
                    key={c.cityId}
                    handleSameValueOnChange={true}
                    value={c.cityId}
                    stateToCheck={returnCodesState.selectedCities}
                    handle={(cityId: string | null) => {
                      if (cityId) {
                        handleSelectCity(cityId);
                      }
                    }}
                    valueToShow={c.cityNameTranslations[language] || ''}
                    bgColor={'white'}
                  ></FiltersCheckbox>
                ))}
            </Box>
          </Box>

          <Button width={'100%'} onClick={handleResetFilters} mt={10} mb={5} bgColor={colors.beige[50]}>
            {translations('return_codes_filters_modal_reset_filters_button')}
          </Button>

          <Button width={'100%'} variant="orangeSolid" onClick={onClose}>
            {translations('return_codes_filters_modal_apply_filters_button')}
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
