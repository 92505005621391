import React from 'react';
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  ListItem,
  OrderedList,
} from '@chakra-ui/react';

interface ReturnCodesHowItWorksAccordionItemProps {
  header: string;
  listItems: string[];
}

export const ReturnCodesHowItWorksAccordionItem = ({ header, listItems }: ReturnCodesHowItWorksAccordionItemProps) => {
  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box as="span" flex="1" textAlign="left" fontWeight={'bold'}>
            {header}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <OrderedList>
          {listItems.map((item) => (
            <ListItem>{item}</ListItem>
          ))}
        </OrderedList>
      </AccordionPanel>
    </AccordionItem>
  );
};
