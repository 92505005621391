import { generatePath } from 'react-router-dom';

import { Paginated } from '../common/models/paginated';
import { apiPaths } from '../config/apiPaths';
import { InventoryCountMissingItemResponse } from '../modules/InventoryCounts/model/inventory-count-missing-item-response';
import { InventoryCountResponse } from '../modules/InventoryCounts/model/inventory-count-response';
import { InventoryCountsQueryRequest } from '../modules/InventoryCounts/model/inventory-counts-query-request';
import { InventoryCountsSubmitRequest } from '../modules/InventoryCounts/model/inventory-counts-submit-request';
import { InventoryCountsSubmitResponse } from '../modules/InventoryCounts/model/inventory-counts-submit-response';
import { InventoryCountsUpdateRequest } from '../modules/InventoryCounts/model/inventory-counts-update-request';
import axios from './axios';

export const getInventoryCounts = async (
  inventoryCountsQueryParams: InventoryCountsQueryRequest,
  offset: number,
  limit: number,
): Promise<Paginated<InventoryCountResponse>> => {
  return axios
    .get<Paginated<InventoryCountResponse>>(apiPaths.INVENTORY_COUNTS_PATH, {
      params: { ...inventoryCountsQueryParams, offset, limit },
    })
    .then((response) => response.data);
};

export const getInventoryCount = async (inventoryCountId: string): Promise<InventoryCountResponse> => {
  return axios
    .get<InventoryCountResponse>(generatePath(apiPaths.INVENTORY_COUNTS_INVENTORY_COUNT_PATH, { inventoryCountId }))
    .then((response) => response.data);
};

export const getInventoryCountSnapshot = async (inventoryCountId: string): Promise<InventoryCountResponse> => {
  return axios
    .get<InventoryCountResponse>(generatePath(apiPaths.INVENTORY_COUNTS_INVENTORY_COUNT_SNAPSHOT, { inventoryCountId }))
    .then((response) => response.data);
};
export const createInventoryCountSnapshot = async (inventoryCountId: string): Promise<InventoryCountResponse> => {
  return axios
    .post<InventoryCountResponse>(
      generatePath(apiPaths.INVENTORY_COUNTS_INVENTORY_COUNT_SNAPSHOT, { inventoryCountId }),
    )
    .then((response) => response.data);
};

export const createInventoryCount = async (restaurantId: string): Promise<InventoryCountResponse> => {
  return axios
    .post<InventoryCountResponse>(generatePath(apiPaths.INVENTORY_COUNTS_RESTAURANTS_PATH, { restaurantId }))
    .then((response) => response.data);
};

export const updateItemsInInventoryCount = async (
  inventoryCountId: string,
  updateRequest: InventoryCountsUpdateRequest,
): Promise<InventoryCountResponse> => {
  return axios
    .put<InventoryCountResponse>(
      generatePath(apiPaths.INVENTORY_COUNTS_INVENTORY_COUNT_PATH, { inventoryCountId }),
      updateRequest,
    )
    .then((response) => response.data);
};

export const getInventoryCountMissingItems = async (
  inventoryCountId: string,
  offset: number,
  limit: number,
): Promise<Paginated<InventoryCountMissingItemResponse>> => {
  return axios
    .get<Paginated<InventoryCountMissingItemResponse>>(
      generatePath(apiPaths.INVENTORY_COUNTS_INVENTORY_COUNT_MISSING_ITEMS_PATH, { inventoryCountId }),
      {
        params: { offset, limit },
      },
    )
    .then((response) => response.data);
};

export const submitInventoryCount = async (
  inventoryCountId: string,
  submitRequest: InventoryCountsSubmitRequest,
): Promise<InventoryCountsSubmitResponse> => {
  return axios
    .post<InventoryCountsSubmitResponse>(
      generatePath(apiPaths.INVENTORY_COUNTS_INVENTORY_COUNT_SUBMIT_PATH, { inventoryCountId }),
      submitRequest,
    )
    .then((response) => response.data);
};
export const updateInventoryCount = async (
  inventoryCountId: string,
  submitRequest: InventoryCountsSubmitRequest,
): Promise<InventoryCountsSubmitResponse> => {
  return axios
    .patch<InventoryCountsSubmitResponse>(
      generatePath(apiPaths.INVENTORY_COUNTS_INVENTORY_COUNT_PATH, { inventoryCountId }),
      submitRequest,
    )
    .then((response) => response.data);
};
