import React from 'react';
import { Button, Flex, Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';

import { useTranslations } from '../../../../contexts/LocalizationContext';

interface ReturnCodesDeactivateConfirmationForMoreThanOneActiveModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
}

export const ReturnCodesDeactivateConfirmationForMoreThanOneActiveModal = ({
  isOpen,
  onClose,
  onAccept,
}: ReturnCodesDeactivateConfirmationForMoreThanOneActiveModalProps) => {
  const translations = useTranslations();
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {translations('return_codes_deactivate_more_than_one_active_confirmation_modal_header')}
        </ModalHeader>
        <ModalCloseButton />
        <Flex h="100%" display="column" p={2}>
          <Text mb={2} fontSize={'small'}>
            {translations('return_codes_deactivate_more_than_one_active_confirmation_modal_content')}
          </Text>
          <Button width={'100%'} colorScheme={'red'} mb={2} onClick={onAccept}>
            {translations('return_codes_deactivate_more_than_one_active_confirmation_modal_confirm_button')}
          </Button>
          <Button width={'100%'} mb={2} onClick={onClose}>
            {translations('return_codes_deactivate_more_than_one_active_confirmation_modal_cancel_button')}
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
