import { Badge, Box, Button, HStack, Image, Text, useDisclosure } from '@chakra-ui/react';
import { isBefore, parseISO, subDays } from 'date-fns';

import { getIconPath } from '../../../../common/components/Navbar/Menu/utils/menuHelper';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useDeactivateReturnCode } from '../../../../services/return-codes';
import { useReturnCodesContext } from '../../context/hooks/useReturnCodesContext';
import { ActiveReturnCode } from '../../context/model/ReturnCodesResult';
import { stringToDate } from '../../utils';
import { handleDownloadSingleReturnQrPoster } from '../ReturnCodesCreated/handleDownloadSingleReturnQrPoster';
import { ReturnCodesDeactivateConfirmationForMoreThanOneActiveModal } from './ReturnCodesDeactivateConfirmationForMoreThanOneActiveModal';
import { ReturnCodesDeactivateConfirmationForOneActiveModal } from './ReturnCodesDeactivateConfirmationForOneActiveModal';

interface ReturnCodesManageModalActiveCodeProps {
  activeCode: ActiveReturnCode;
  refetchLocations: () => void;
  refetchReuseCodes: () => void;
  isLastActive: boolean;
}

export const ReturnCodesManageModalActiveCode = ({
  activeCode,
  refetchLocations,
  refetchReuseCodes,
  isLastActive,
}: ReturnCodesManageModalActiveCodeProps) => {
  const translations = useTranslations();
  const { returnCodesState, handleCloseManageModal, handleOpenDeactivatedModal } = useReturnCodesContext();
  const location = returnCodesState.locations.find((l) => l.locationId === returnCodesState.locationIdToManage);
  const { mutate } = useDeactivateReturnCode(activeCode.id);
  const {
    isOpen: isConfirmationModalOpen,
    onOpen: onConfirmationModalOpen,
    onClose: onConfirmationModalClose,
  } = useDisclosure();

  const onConfirmationModalAccept = () => {
    mutate(undefined, {
      onSuccess: () => {
        handleOpenDeactivatedModal();
        refetchLocations();
        refetchReuseCodes();
        onConfirmationModalClose();
      },
      onError: () => {
        handleCloseManageModal();
      },
    });
  };

  const isOlderThan30Days = (dateString: string): boolean => {
    const date = parseISO(dateString);
    const thirtyDaysAgo = subDays(new Date(), 2);
    return isBefore(date, thirtyDaysAgo);
  };

  return (
    <Box key={activeCode.name} borderRadius="md" padding={4} bg="#F8F8F8" mb={'10px'}>
      {isOlderThan30Days(activeCode.recordCreatedAt) ? (
        <Badge colorScheme={'yellow'}>{'Active for 30+ days'}</Badge>
      ) : (
        <></>
      )}
      <HStack justify="space-between" mb={2}>
        <Text fontWeight={'bold'} fontSize={'medium'}>
          {activeCode.name}
        </Text>
        <Image src={getIconPath('qr_code_2_gray.svg')}></Image>
      </HStack>
      <Text>
        {translations('return_codes_manage_modal_added')}: {stringToDate(activeCode.recordCreatedAt)}
      </Text>
      <Text>
        {translations('return_codes_manage_modal_type')}: {activeCode.type}
      </Text>
      <Text>
        {translations('return_codes_manage_modal_statistics')}:{' '}
        <Text as={'span'} fontWeight={'bold'}>
          {activeCode.last30DaysReturns}
        </Text>{' '}
        {translations('return_codes_manage_modal_returns_last_30_days')}
      </Text>
      <HStack justify="flex-end">
        <Button size="sm" variant="white">
          <Text
            fontWeight={'bold'}
            onClick={() => {
              handleDownloadSingleReturnQrPoster({
                returnCode: activeCode,
                restaurant: {
                  id: location?.locationId || '',
                  restaurantName: location?.locationName || '',
                  metaCategories: location?.metaCategories.map((mc) => ({ id: mc })) || [],
                },
              });
              handleCloseManageModal();
            }}
          >
            {translations('return_codes_manage_modal_print_button')}
          </Text>
        </Button>
        <Button size="sm" colorScheme="red" onClick={onConfirmationModalOpen}>
          <Text fontWeight={'bold'}>{translations('return_codes_manage_modal_deactivate_button')}</Text>
        </Button>
        <ReturnCodesDeactivateConfirmationForMoreThanOneActiveModal
          isOpen={!isLastActive && isConfirmationModalOpen}
          onClose={onConfirmationModalClose}
          onAccept={onConfirmationModalAccept}
        ></ReturnCodesDeactivateConfirmationForMoreThanOneActiveModal>
        <ReturnCodesDeactivateConfirmationForOneActiveModal
          isOpen={isLastActive && isConfirmationModalOpen}
          onClose={onConfirmationModalClose}
          onAccept={onConfirmationModalAccept}
        ></ReturnCodesDeactivateConfirmationForOneActiveModal>
      </HStack>
    </Box>
  );
};
