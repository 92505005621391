import { SystemStyleObject } from '@chakra-ui/styled-system';
import { format, parseISO } from 'date-fns';

import { FileExtension } from '../../common/models/enums/file.extension';

export const stringToDate = (dateString: string) => format(parseISO(dateString), 'dd/MM/yyyy');
export const getReturnCodePosterFileName = (returnCodeName: string) => `${returnCodeName}-poster.${FileExtension.PDF}`;
export const visibleScrollStyle: SystemStyleObject = {
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
};
