import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Flex, Text } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';

import { FullPageLoader } from '../../../../common/components/Loader/FullPageLoader';
import { TableType } from '../../../../common/components/Table/model/enums/table-type.enum';
import { Table } from '../../../../common/components/Table/Table';
import { Paginated } from '../../../../common/models/paginated';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useLocalizationContext } from '../../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { useReturnCodesContext } from '../../context/hooks/useReturnCodesContext';
import { ReturnCodesLocation } from '../../context/model/ReturnCodesLocation';
import { ReturnCodesDeactivatedModal } from '../ReturnCodesManage/ReturnCodesDeactivatedModal';
import { ReturnCodesNoReturnCodeModal } from '../ReturnCodesNoReturnCodeModal/ReturnCodeNoReturnCodeModal';
import { ReturnCodesUnusedReturnCodeModal } from '../ReturnCodesUnusedReturnCodeModal/ReturnCodeUnusedReturnCodeModal';
import { ReturnCodesTableRow } from './model/return-codes-table-row';
import { activeCodesCount, lastUpdated } from './common';

interface ReturnCodesTableProps {
  data: Paginated<ReturnCodesLocation> | undefined;
  isFetching: boolean;
}

export const ReturnCodesTable = ({ data, isFetching }: ReturnCodesTableProps) => {
  const {
    returnCodesState,
    handleLocationCheck,
    handleSetLocations,
    handleSetPage,
    handleOpenManageModal,
    handleCloseDeactivatedModal,
    handleOpenUnusedModal,
    handleOpenNoReturnCodeModal,
    handleAllLocationsCheck,
  } = useReturnCodesContext();
  const translations = useTranslations();
  const { language } = useLocalizationContext();
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    handleSetLocations(data?.items || []);
  }, [data, handleSetLocations]);

  const columns: ColumnDef<ReturnCodesTableRow>[] = [
    {
      accessorKey: 'uniqueId',
      header: () => {
        return (
          <Checkbox
            colorScheme="orange"
            isChecked={selectAll}
            onChange={(e) => {
              handleAllLocationsCheck(e.target.checked);
              setSelectAll(e.target.checked);
            }}
          ></Checkbox>
        );
      },
      cell: ({ row }) => (
        <Checkbox
          colorScheme="orange"
          isChecked={returnCodesState.selectedLocations.some((sl) => sl.locationId === row.original.locationId)}
          onChange={(e) =>
            handleLocationCheck(e.target.checked, {
              locationId: row.original.locationId,
              locationName: row.original.locationName,
              locationMetaCategories: row.original.metaCategories,
            })
          }
        ></Checkbox>
      ),
    },
    {
      accessorKey: 'locationId',
      header: translations('return_codes_table_header_location_id'),
      cell: ({ row }) => (
        <Flex alignItems="center">
          <Text>{row.original.locationId}</Text>
        </Flex>
      ),
    },
    {
      accessorKey: 'locationName',
      header: translations('return_codes_table_header_location_name'),
      cell: ({ row }) => (
        <Flex alignItems="center">
          <Text>{row.original.locationName}</Text>
        </Flex>
      ),
    },
    {
      accessorKey: 'city',
      header: translations('return_codes_table_header_city'),
      cell: ({ row }) => (
        <Flex alignItems="center">
          <Text>{row.original.city}</Text>
        </Flex>
      ),
    },
    {
      accessorKey: 'activeCodesCount',
      header: translations('return_codes_table_header_active_codes'),
      cell: ({ row }) => (
        <>
          <Flex alignItems="center">
            {activeCodesCount(
              row.original.activeCodesCount,
              row.original.codesNotUsedForLast30Days,
              () => {
                handleOpenUnusedModal(
                  row.original.locationId,
                  row.original.locationName,
                  row.original.codesNotUsedForLast30Days,
                );
              },
              () => {
                handleOpenNoReturnCodeModal(
                  row.original.locationId,
                  row.original.locationName,
                  row.original.metaCategories,
                );
              },
            )}
          </Flex>
        </>
      ),
    },
    {
      accessorKey: 'lastUpdated',
      header: translations('return_codes_table_header_last_updated'),
      cell: ({ row }) => {
        return (
          <Flex alignItems="center">
            {lastUpdated(row.original.lastUpdated, translations('return_codes_table_no_active_code'))}
          </Flex>
        );
      },
    },
    {
      accessorKey: 'actions',
      header: translations('return_codes_table_header_actions'),
      cell: ({ row }) => (
        <Flex alignItems="center" width={['100%', '100%', '100%']}>
          <Button
            variant="orangeSolid"
            width={'100%'}
            onClick={() => {
              handleOpenManageModal(row.original.locationId);
            }}
          >
            {translations('return_codes_table_action_manage_return_codes')}
          </Button>
        </Flex>
      ),
    },
  ];

  return (
    <FullPageLoader show={isFetching}>
      <Table<ReturnCodesTableRow>
        type={TableType.PAGINATED}
        data={returnCodesState.locations.map(
          (l): ReturnCodesTableRow => ({
            ...l,
            uniqueId: `regular_${l.locationId}`,
            id: `regular_${l.locationId}`,
            city: l.cityNameTranslations[language] || '',
          }),
        )}
        page={returnCodesState.page}
        totalPages={data?.totalPages || 1}
        columns={columns}
        variant={'returnCodesRestaurants'}
        onPageChanged={handleSetPage}
      />
      <ReturnCodesDeactivatedModal
        isOpen={returnCodesState.isDeactivatedModalOpen}
        onClose={() => {
          handleCloseDeactivatedModal();
        }}
      ></ReturnCodesDeactivatedModal>

      <ReturnCodesUnusedReturnCodeModal></ReturnCodesUnusedReturnCodeModal>
      <ReturnCodesNoReturnCodeModal></ReturnCodesNoReturnCodeModal>
    </FullPageLoader>
  );
};
