import React from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';

import { useTranslations } from '../../../../contexts/LocalizationContext';
import { ReturnCodesHowItWorksModal } from './ReturnCodesHowItWorksModal';

export const ReturnCodesHowItWorks = () => {
  const translations = useTranslations();
  const { onClose, isOpen, onToggle } = useDisclosure();

  return (
    <>
      <Button
        variant="blackSolid"
        _focus={{ boxShadow: 'none' }}
        _active={{ bg: 'black' }}
        onClick={() => {
          onToggle();
        }}
        width={'100%'}
      >
        {translations('return_codes_how_it_works_button')}
      </Button>
      <ReturnCodesHowItWorksModal onClose={onClose} isOpen={isOpen}></ReturnCodesHowItWorksModal>
    </>
  );
};
