import React from 'react';
import { Button, Flex, Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';

import { useTranslations } from '../../../../contexts/LocalizationContext';

interface ReturnCodesDeactivateConfirmationForOneActiveModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ReturnCodesDeactivatedModal = ({
  isOpen,
  onClose,
}: ReturnCodesDeactivateConfirmationForOneActiveModalProps) => {
  const translations = useTranslations();
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{translations('return_codes_deactivated_modal_header')}</ModalHeader>
        <ModalCloseButton />
        <Flex h="100%" display="column" p={2}>
          <Text mb={2} fontSize={'small'}>
            {translations('return_codes_deactivated_modal_content')}
          </Text>
          <Button width={'100%'} mb={2} onClick={onClose}>
            {translations('return_codes_deactivated_modal_close_button')}
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
