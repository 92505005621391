import React from 'react';
import { Button } from '@chakra-ui/react';

import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useReturnCodesContext } from '../../context/hooks/useReturnCodesContext';
import { ReturnCodesCreatedModal } from '../ReturnCodesCreated/ReturnCodesCreatedModal';
import { ReturnCodesCreateModal } from './ReturnCodesCreateModal';

interface ReturnCodesCreateProps {
  refetchLocations: () => void;
  refetchReturnCodes: () => void;
}

export const ReturnCodesCreate = ({ refetchLocations, refetchReturnCodes }: ReturnCodesCreateProps) => {
  const translations = useTranslations();
  const { returnCodesState, handleOpenCreateModal } = useReturnCodesContext();
  const checkedLocationsCount = returnCodesState.selectedLocations.length;

  return (
    <>
      <Button
        isDisabled={checkedLocationsCount === 0}
        variant="orangeSolid"
        onClick={() => handleOpenCreateModal()}
        width={'100%'}
      >
        {translations('return_codes_create_return_code_for_locations_button', {
          '{{locationCount}}':
            checkedLocationsCount > 0
              ? checkedLocationsCount.toString()
              : translations('return_codes_create_return_code_for_locations_button_multiple'),
        })}
      </Button>
      <ReturnCodesCreateModal
        refetchLocations={refetchLocations}
        refetchReturnCodes={refetchReturnCodes}
      ></ReturnCodesCreateModal>
      <ReturnCodesCreatedModal></ReturnCodesCreatedModal>
    </>
  );
};
