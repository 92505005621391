import React, { useEffect } from 'react';
import { Button, Checkbox, Flex, Text } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';

import { FullPageLoader } from '../../../../common/components/Loader/FullPageLoader';
import { TableType } from '../../../../common/components/Table/model/enums/table-type.enum';
import { Table } from '../../../../common/components/Table/Table';
import { Paginated } from '../../../../common/models/paginated';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useLocalizationContext } from '../../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { useReturnCodesContext } from '../../context/hooks/useReturnCodesContext';
import { ReturnCodesLocation } from '../../context/model/ReturnCodesLocation';
import { ReturnCodesDeactivatedModal } from '../ReturnCodesManage/ReturnCodesDeactivatedModal';
import { ReturnCodesTableRow } from './model/return-codes-table-row';
import { activeCodesCount, lastUpdated } from './common';

interface ReturnCodesTableProps {
  data: Paginated<ReturnCodesLocation> | undefined;
  isFetching: boolean;
}

export const ReturnCodesMobileTable = ({ data, isFetching }: ReturnCodesTableProps) => {
  const {
    returnCodesState,
    handleLocationCheck,
    handleSetLocations,
    handleSetPage,
    handleCloseDeactivatedModal,
    handleOpenManageModal,
    handleOpenUnusedModal,
    handleOpenNoReturnCodeModal,
  } = useReturnCodesContext();
  const translations = useTranslations();
  const { language } = useLocalizationContext();

  useEffect(() => {
    handleSetLocations(data?.items || []);
  }, [data, handleSetLocations]);

  const columns: ColumnDef<ReturnCodesTableRow>[] = [
    {
      id: 'firstColumn',
      header: '',
      size: 195,
      cell: ({ row }) => (
        <>
          <Flex
            display="flex"
            alignItems="flex-start"
            align="flex-start"
            justify="space-between"
            flexWrap="nowrap"
            mb={5}
          >
            <Checkbox
              colorScheme="orange"
              isChecked={returnCodesState.selectedLocations.some((sl) => sl.locationId === row.original.locationId)}
              onChange={(e) =>
                handleLocationCheck(e.target.checked, {
                  locationId: row.original.locationId,
                  locationName: row.original.locationName,
                  locationMetaCategories: row.original.metaCategories,
                })
              }
              mr={5}
            ></Checkbox>
            <Flex direction="column" mr={5}>
              <Text fontSize="sm" fontWeight={'bold'}>
                {translations('return_codes_table_header_location_id')}
              </Text>
              <Text fontSize="md">{row.original.locationId}</Text>
            </Flex>
          </Flex>
          <Flex
            display="flex"
            alignItems="flex-start"
            align="flex-start"
            justify="space-between"
            flexWrap="nowrap"
            mb={5}
          >
            <Flex direction="column" mr={5}>
              <Text fontSize="sm" fontWeight={'bold'}>
                {translations('return_codes_table_header_city')}
              </Text>
              <Text fontSize="md">{row.original.city}</Text>
            </Flex>
          </Flex>
          <Flex
            display="flex"
            alignItems="flex-start"
            align="flex-start"
            justify="space-between"
            flexWrap="nowrap"
            mb={5}
          >
            <Flex direction="column" mr={5}>
              <Text fontSize="sm" fontWeight={'bold'}>
                {translations('return_codes_table_header_last_updated')}
              </Text>
              {lastUpdated(row.original.lastUpdated, translations('return_codes_table_no_active_code'))}
            </Flex>
          </Flex>
        </>
      ),
    },
    {
      id: 'secondColumn',
      header: '',
      size: 195,
      cell: ({ row }) => (
        <>
          <Flex
            display="flex"
            alignItems="flex-start"
            align="flex-start"
            justify="space-between"
            flexWrap="nowrap"
            mb={5}
          >
            <Flex direction="column">
              <Text fontSize="sm" fontWeight={'bold'}>
                {translations('return_codes_table_header_location_name')}
              </Text>
              <Text fontSize="md">{row.original.locationName}</Text>
            </Flex>
          </Flex>

          <Flex
            display="flex"
            alignItems="flex-start"
            align="flex-start"
            justify="space-between"
            flexWrap="nowrap"
            mb={5}
          >
            <Flex direction="column" mr={5}>
              <Text fontSize="sm" fontWeight={'bold'}>
                {translations('return_codes_table_header_active_codes')}
              </Text>

              <Flex direction={'row'}>
                {activeCodesCount(
                  row.original.activeCodesCount,
                  row.original.codesNotUsedForLast30Days,
                  () => {
                    handleOpenUnusedModal(
                      row.original.locationId,
                      row.original.locationName,
                      row.original.codesNotUsedForLast30Days,
                    );
                  },
                  () => {
                    handleOpenNoReturnCodeModal(
                      row.original.locationId,
                      row.original.locationName,
                      row.original.metaCategories,
                    );
                  },
                )}
              </Flex>
            </Flex>
          </Flex>

          <Flex
            display="flex"
            alignItems="flex-start"
            align="flex-start"
            justify="space-between"
            flexWrap="nowrap"
            mb={5}
          >
            <Flex direction="column" mr={5}>
              <Button
                variant="orangeSolid"
                width={'100%'}
                onClick={() => {
                  handleOpenManageModal(row.original.locationId);
                }}
              >
                {translations('return_codes_table_action_manage_return_codes')}
              </Button>
            </Flex>
          </Flex>
        </>
      ),
    },
  ];

  return (
    <FullPageLoader show={isFetching}>
      <Table<ReturnCodesTableRow>
        type={TableType.PAGINATED}
        data={returnCodesState.locations.map(
          (l): ReturnCodesTableRow => ({
            ...l,
            uniqueId: `mobile_${l.locationId}`,
            id: `mobile_${l.locationId}`,
            city: l.cityNameTranslations[language] || '',
          }),
        )}
        page={returnCodesState.page}
        totalPages={data?.totalPages || 1}
        columns={columns}
        variant={'returnCodesRestaurants'}
        onPageChanged={handleSetPage}
      />
      <ReturnCodesDeactivatedModal
        isOpen={returnCodesState.isDeactivatedModalOpen}
        onClose={() => {
          handleCloseDeactivatedModal();
        }}
      ></ReturnCodesDeactivatedModal>
    </FullPageLoader>
  );
};
