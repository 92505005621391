import React, { useState } from 'react';
import { Box, Button, Flex, Input, ModalCloseButton, ModalHeader, Radio, RadioGroup, Text } from '@chakra-ui/react';

import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useReturnCodesCreate } from '../../../../services/return-codes';
import { useReturnCodesContext } from '../../context/hooks/useReturnCodesContext';
import { ReturnCodeCreateRequest } from '../../context/model/ReturnCodeCreate.request';
import { ReturnCodeType } from '../../context/model/ReturnCodeType';
import { stringToDate } from '../../utils';
import { handleDownloadSingleReturnQrPoster } from '../ReturnCodesCreated/handleDownloadSingleReturnQrPoster';

interface ReturnCodesCreateSingleCodeModalContentProps {
  location: { locationId: string; locationName: string };
  refetchLocations: () => void;
  refetchReturnCodes: () => void;
}

export const ReturnCodesCreateSingleCodeModalContent = ({
  location,
  refetchLocations,
  refetchReturnCodes,
}: ReturnCodesCreateSingleCodeModalContentProps) => {
  const translations = useTranslations();
  const [returnCodeType, setReturnCodeType] = useState<ReturnCodeType>(ReturnCodeType.REGULAR);
  const [gracePeriod, setGracePeriod] = useState<number | undefined>();
  const { handleCloseCreateModal, handleCloseCreateModalAfterCreate } = useReturnCodesContext();

  const { mutate: createRestaurantsReturnCodes } = useReturnCodesCreate();

  return (
    <>
      <ModalHeader>
        {translations('return_codes_create_modal_header', {
          '{{locationCount}}': '1',
        })}
      </ModalHeader>
      <ModalCloseButton />
      <Flex h="100%" display="column" p={2}>
        <Text mb={2} fontSize={'medium'} fontWeight={'bold'}>
          {location?.locationName}
        </Text>
        <Box borderRadius="md" padding={4} bg="#F8F8F8" mb={'10px'} fontSize={'small'}>
          {translations('return_codes_create_single_modal_description')}
        </Box>
        <Box borderRadius="md" padding={4} mb={'5px'} fontSize={'small'}>
          <Text fontWeight={'bold'}>{translations('return_codes_create_modal_create_return_code_name')}</Text>
          <Text>{location?.locationName + '_' + stringToDate(new Date().toISOString())}</Text>
        </Box>
        <Box borderRadius="md" padding={4} mb={'5px'} fontSize={'small'}>
          <Text fontWeight={'bold'}>{translations('return_codes_create_modal_create_select_code_type')}</Text>
          <RadioGroup
            onChange={(val: ReturnCodeType) => {
              setGracePeriod(undefined);
              setReturnCodeType(val);
            }}
            value={returnCodeType}
            mb={'2'}
          >
            <Radio size="sm" value={ReturnCodeType.REGULAR} mr={'5'}>
              {translations('return_codes_create_modal_regular_code_type')}
            </Radio>
            <Radio size="sm" value={ReturnCodeType.OPEN_HOURS}>
              {translations('return_codes_create_modal_oh_code_type')}
              {'*'}
            </Radio>
          </RadioGroup>
          <Text color={'#888888'}>
            {translations('return_codes_create_modal_create_select_code_type_additional_info')}
          </Text>
        </Box>
        <Box
          hidden={returnCodeType !== ReturnCodeType.OPEN_HOURS}
          borderRadius="md"
          padding={4}
          mb={'5px'}
          fontSize={'small'}
        >
          <Text fontWeight={'bold'}>{translations('return_codes_create_modal_create_grace_period')}</Text>
          <Input
            type={'number'}
            onChange={(value) => {
              setGracePeriod(Number(value.target.value));
            }}
            value={gracePeriod}
          ></Input>
          <Text color={'#888888'}>{translations('return_codes_create_modal_create_grace_period_additional_info')}</Text>
        </Box>

        <Button
          width={'100%'}
          variant="orangeSolid"
          display={'box'}
          onClick={() => {
            const request: ReturnCodeCreateRequest = {
              restaurantIds: [location?.locationId],
              type: returnCodeType,
              gracePeriod: gracePeriod || null,
            };

            createRestaurantsReturnCodes(
              { request },
              {
                onSuccess: async (data) => {
                  await handleDownloadSingleReturnQrPoster(data.createdReturnCodes[0]);
                  refetchLocations();
                  refetchReturnCodes();
                  handleCloseCreateModalAfterCreate();
                },
              },
            );
          }}
          mb={2}
        >
          {translations('return_codes_create_modal_create_new_code')}
        </Button>

        <Button width={'100%'} colorScheme={'gray'} display={'box'} onClick={handleCloseCreateModal}>
          {translations('return_codes_create_modal_cancel')}
        </Button>
      </Flex>
    </>
  );
};
