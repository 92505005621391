import React from 'react';
import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';

import { useReturnCodesContext } from '../../context/hooks/useReturnCodesContext';
import { ReturnCodesCreateMultipleCodesModalContent } from './ReturnCodesCreateMultipleCodesModalContent';
import { ReturnCodesCreateSingleCodeModalContent } from './ReturnCodesCreateSingleCodeModalContent';

interface ReturnCodesCreateModalProps {
  refetchLocations: () => void;
  refetchReturnCodes: () => void;
}

export const ReturnCodesCreateModal = ({ refetchLocations, refetchReturnCodes }: ReturnCodesCreateModalProps) => {
  const { returnCodesState, handleCloseCreateModal } = useReturnCodesContext();

  const locationsToCreate = returnCodesState.overridenLocationToCreate
    ? [returnCodesState.overridenLocationToCreate]
    : returnCodesState.selectedLocations;

  return (
    <Modal isOpen={returnCodesState.isCreateModalOpen} onClose={handleCloseCreateModal} size="md">
      <ModalOverlay />
      <ModalContent h={'auto'} transform="none">
        {locationsToCreate.length > 1 ? (
          <ReturnCodesCreateMultipleCodesModalContent
            locations={locationsToCreate}
            refetchLocations={refetchLocations}
            refetchReturnCodes={refetchReturnCodes}
          ></ReturnCodesCreateMultipleCodesModalContent>
        ) : (
          <ReturnCodesCreateSingleCodeModalContent
            location={locationsToCreate[0]}
            refetchLocations={refetchLocations}
            refetchReturnCodes={refetchReturnCodes}
          ></ReturnCodesCreateSingleCodeModalContent>
        )}
      </ModalContent>
    </Modal>
  );
};
