import React from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';

import { DeactivatedReturnCode } from '../../context/model/ReturnCodesResult';
import { stringToDate } from '../../utils';

interface ReturnCodesManageModalLastDeactivatedCodeProps {
  deactivatedCode: DeactivatedReturnCode;
  lastDeactivatedCodeHeader: string;
}

export const ReturnCodesManageModalLastDeactivatedCode = ({
  deactivatedCode,
  lastDeactivatedCodeHeader,
}: ReturnCodesManageModalLastDeactivatedCodeProps) => (
  <>
    <Text mb={2} fontSize={'large'} fontWeight={'bold'}>
      {lastDeactivatedCodeHeader}
    </Text>
    <Box key={deactivatedCode.name} borderRadius="md" padding={4} bg="#F8F8F8" mb={'10px'} color={'#555555'}>
      <HStack justify="space-between" mb={2}>
        <Text fontWeight={'bold'} fontSize={'medium'}>
          {deactivatedCode.name}
        </Text>
      </HStack>
      <Text>Deactivated at: {stringToDate(deactivatedCode.deactivatedAt)}</Text>
      <Text>Type: {deactivatedCode.type}</Text>
    </Box>
  </>
);
